import React from 'react';
import {
  Container, Col, Row,
} from 'reactstrap';

const logo = `${process.env.PUBLIC_URL}/img/logo.png`;

const Footer = () => (
  <div className="footer__main-wrapper">
    <Row className="footer__container container">
      <Col lg={12} className="px-0">
        <footer>
          <div className="footer__body-container">
            <Row className="footer__body-content">
              <Col className="footer__body-content" xs={12} md={12}>
              <img className="footer__logo" src={logo} alt="" />
              </Col>
              {/*<Col xs={5} md={2} className="footer__image-wrapper">
                <div className="footer__image-container">
                  <img src={footerimg} alt="" />
              </Col>*/}
            </Row>
          </div>
        </footer>
      </Col>
    </Row>
  </div>
);

export default Footer;
