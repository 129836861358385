import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Header from '../Navigation/Components/Toolbar';
import SideDrawer from '../Navigation/Components/SideDrawer';
import BackDrop from '../Navigation/Components/Backdrop';


class Layout extends Component {
  static propTypes = {
    
  };
  constructor(props) {
    super(props);
    this.state = {
      sideDrawerOpen: false,
    };
  }

  drawerToggleClickHandler = () =>{
    this.setState((prevState) => {
      return {sideDrawerOpen: !prevState.sideDrawerOpen};
    });
  };

  backdropClickHandler = () => {
    this.setState({sideDrawerOpen: false});
  };

  render() {
  const { sidebar } = this.props;
  
  let backdrop;

    if(this.state.sideDrawerOpen) {
      backdrop = <BackDrop click={this.backdropClickHandler} />;
    }
    
    return (
      <div>
        <Header drawerClickHandler={this.drawerToggleClickHandler} />
        <SideDrawer drawerClickHandler={this.drawerToggleClickHandler} show={this.state.sideDrawerOpen}/>
        {backdrop}
      </div>
    );
  }
}

export default withRouter(Layout);
