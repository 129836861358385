import React from 'react';
import { Container, Button } from 'reactstrap'
import ToggleButton from './DrawerButton'
import LogoutIcon from 'mdi-react/LogoutIcon';

const logo = `${process.env.PUBLIC_URL}/img/logo.png`;

const Toolbar = props => {
 

  return(
  <React.Fragment>
  <div className="banner__container">
  </div>
    <header className="toolbar">
     <Container className="toolbar__container">
      <nav className="toolbar__navigation">
        {/*<div className="toolbar__logo"><a href="/dashboard"><img src={logo} alt="logo"/></a></div>*/}
        <div className="toolbar__navigation-items">
          <img className="header__logo" src={logo} alt=""/>
        </div>
        <div className="spacer"></div>
        <div className="toolbar__logout">
        </div>
        <div>
          <ToggleButton click={props.drawerClickHandler}/>
        </div>
      </nav>
    </Container>
    </header>
    </React.Fragment>
);
};

export default Toolbar ;