import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ThemeProps, RTLProps } from '../../shared/prop-types/ReducerProps';

class MainWrapper extends PureComponent {
  static propTypes = {

    location: PropTypes.shape({}).isRequired,
  };

  render() {
    const {
       location, children,
    } = this.props;

    return (
      <div>
        <div className="wrapper">
          {children}
        </div>
      </div>
    );
  }
}

export default withRouter((MainWrapper));
