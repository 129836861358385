import React, { useState, useEffect } from 'react';
import { Button } from 'reactstrap';
import CloseIcon from './CloseIcon';

const avatar = `${process.env.PUBLIC_URL}/img/avatar.png`;
const logout = `${process.env.PUBLIC_URL}/img/logout.png`;

const sideDrawer = props => {

  const [userName, setUserName] = useState([]);
  const [controlState, setControlState] = useState(0);
  const token = localStorage.getItem('jwtToken');

  useEffect(() => {
   /* if(token){
    APICALL.SESSION.sessionFind({ redisKey: token }, token, (error, response) => {
      if(error && error.response) {
          console.log(error);
      }else if(error){
        store.addNotification({
          title: "Error!",
          message: "Megbaszódott a redis",
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          showIcon:"true"
        });
      }
      else {
        setUserName(JSON.parse(response.data.user).namefordisplay);
      }
    });
  }*/
  },[controlState]);


 let drawerClasses = 'side__drawer';
 if (props.show) {
     drawerClasses = 'side__drawer open';
 }


  return (
  <nav className={drawerClasses}>
      <div className="sidedrawer__user">
            <div className="spacer"></div>
            <CloseIcon click={props.drawerClickHandler}/>
      </div>
      <ul>
        <li></li>
        <li></li>
      </ul>
    </nav>
  );
};

export default sideDrawer;