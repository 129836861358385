import React, { Component } from 'react';
import {
  Button, Container, Col, Row,
} from 'reactstrap';
import Footer from '../Footer/Footer';
import CellPhoneLink from 'mdi-react/CellphoneLinkIcon';
import TabletCellphoneIcon from 'mdi-react/TabletCellphoneIcon';
import BadgeIcon from 'mdi-react/AccountBadgeAlertOutlineIcon';


class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deviceData:{}
    };
 
  }
  componentDidMount(){
    if(WURFL){
    this.setState({deviceData:WURFL})
    }
  }
  
  render() {
    
    
    return (
        <div className="viewport__aux">
        <Container>
            <h2 className="text-center mb-5 mt-1">Device Information Detector</h2>
        <Row>
          <Col className="info__category" xs={12} md={6} lg={4}>
              <BadgeIcon className="info__category-icon"/><div className="info__category-name">Device Name:&nbsp;</div>
              <div>{this.state.deviceData.complete_device_name}</div>
          </Col>
          <Col className="info__category" xs={12} md={6} lg={4}>
             <CellPhoneLink className="info__category-icon"/><div className="info__category-name">Device Form Factor:&nbsp;</div>
             <div>{this.state.deviceData.form_factor}</div>
          </Col>
          <Col className="info__category" xs={12} md={6} lg={4}>
             <TabletCellphoneIcon className="info__category-icon"/><div className="info__category-name">Is mobile:&nbsp;</div>
             {(this.state.deviceData.is_mobile==true)?
             <div>true</div>:
             <div>false</div>
             }
          </Col>
        </Row>
        </Container>
        <Footer />
        </div>
    );
  }
}
export default Dashboard;
